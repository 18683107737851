import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

export default function ProjectRow({project, deleteProject, duplicateProject, exportCSV, previewLink}) {
  let [link, setLink] = useState(`http://api.eyeseenav.com/tests?cell=${project.cell}&test=${project.name}`);

  return (
    <tr>
      <td>{project.id}</td>
      <td>
        <Link to={'/projects/' + project.id}>
          <span className="black-text link">{project.name} (<span className="blue-text">{project.cell}</span>)</span>
        </Link>
      </td>
      <td>{moment(project.end_date).format('DD.MM.YYYY.')}</td>
      <td className="link-dnd">
        <i className="material-icons link" title="Link" onClick={e => previewLink(e, link)}>link</i>
        <a href={`https://api.eyeseenav.com/test/data/results?id=${project.id}`} target="_blank">
          <i className="material-icons link" title="CSV Preview">get_app</i>
        </a>
      </td>
      <td>
        <Link to={'/projects/' + project.id}>
          <i className="material-icons link teal-text text-lighten-1" title="Edit">edit</i>
        </Link>
        <i className="material-icons link indigo-text text-lighten-1" onClick={duplicateProject} title="Duplicate">file_copy</i>
        <i className="material-icons link red-text text-lighten-1" onClick={deleteProject} title="Delete">delete</i>
      </td>
    </tr>
  )
}
