import React, {useState} from 'react';

function Modal({isVisible, children}) {

  let modalStyle = isVisible ? {display: 'block'}: {display: 'none'};

  return (
    <div className="app-modal" style={modalStyle}>
      {children}
    </div>
  );
}

export default Modal;
