import React, {useRef, useEffect} from 'react';

export default function Link({link, style, hideLink}) {
  useEffect(() => {
    let link = document.getElementById('link');
    link.select();
  });

  let copyLink = () => {
    let link = document.getElementById('link');
    link.select();
    document.execCommand('copy');
  }

  return (
    <div className="link-preview" style={style} onClick={e => e.stopPropagation()}>
      <input type="text" value={link} id="link"/>
      <i className="material-icons link" onClick={copyLink}>file_copy</i>

      <a href={link} target="_blank">
        <i className="material-icons link open-link">open_in_new</i>
      </a>
    </div>
  )
}
