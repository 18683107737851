import React, {useState, useEffect} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import NextURLs from './NextURLs.jsx';
import M from 'materialize-css';

export default function Task({task, onTaskUpdate, deleteTask, onDragStart, onDragOver, onDrop}) {
  useEffect(() => {
    var elems = document.querySelectorAll('.collapsible');
    var instances = M.Collapsible.init(elems, {});
  }, []);

  let updateFormData = e => {
    let tsk = JSON.parse(JSON.stringify(task));
    tsk[e.target.name] = e.target.value;
    onTaskUpdate(tsk);
  }

  const checkedPremium = e => {
    let tsk = JSON.parse(JSON.stringify(task));
    tsk[e.target.name] = e.target.checked;
    onTaskUpdate(tsk);
  }

  let updateDescription = data => {
    let tsk = JSON.parse(JSON.stringify(task));
    tsk.desc = data
    onTaskUpdate(tsk);
  }

  let updateURLs = urls => {
    let tsk = JSON.parse(JSON.stringify(task));
    tsk.urlCheck = urls;
    onTaskUpdate(tsk);
  }

  let closeAllCollapsibles = () => {
    let collapsibles = document.getElementsByClassName('collapsible');
    for (let i = 0; i < collapsibles.length; i++) {
      let instance = M.Collapsible.getInstance(collapsibles[i]);
      instance.close();
    }
  }

  let callDragStart = (e, task) => {
    closeAllCollapsibles();
    onDragStart(e, task);
  }

  return (
    <ul className="task collapsible">
      <li>
        <div className="collapsible-header" draggable onDragStart={e => callDragStart(e, task)} onDragOver={onDragOver} onDrop={e => onDrop(e, task)}>
          <span className="task-title">
            <i className="material-icons link">drag_indicator</i>
            {task.name}
          </span>
          <i className="material-icons">keyboard_arrow_down</i>
        </div>

        <div className="collapsible-body">
          <span>
            <div className="input-field">
              <input type="text" name="name" value={task.name} onChange={updateFormData} />
              <label className="active">Name</label>
            </div>
            <div className="input-field">
              <input type="text" name="url" value={task.url} onChange={updateFormData}/>
              <label className="active">URL</label>
            </div>
            <NextURLs urls={task.urlCheck} updateURLs={updateURLs} />
          </span>

          <div className="input-field">
            <label className="active">
              <input type="checkbox" className={!!task.premium ? "filled-in": ''} name="premium" value={task.premium} checked={!!task.premium} onChange={checkedPremium}/>
              <span>Premium A+</span>
            </label>
          </div>

          <div className="editor">
            <label className="active">Description</label>
            <CKEditor
              editor={ ClassicEditor }
              data={task.desc}
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  updateDescription(data);
              } }
             />
          </div>
          <div className="ctrl">
            <button className="btn pink lighten-1" onClick={deleteTask}>DELETE TASK</button>
          </div>
        </div>
      </li>
    </ul>
  )
}
