import React from 'react';

export default function DeleteProject({deleteProject, hideModal}) {
  return (
    <div className="delete-project card" onClick={e => e.stopPropagation()}>
      <div className="card-title">
        <h5>Delete Project</h5>
      </div>

      <div className="card-content">
        <p>You are about to delete a project. Are you sure? </p>
      </div>

      <div className="card-footer m-2">
        <button className="btn white black-text" onClick={hideModal}>Cancel</button>
        <button className="btn red lighten-2" onClick={deleteProject}>Delete</button>
      </div>
    </div>
  )
}
