import React, {useEffect, useState} from 'react';
import M from 'materialize-css';
import moment from 'moment';
let instance;

export default function ProjectForm ({project, setProjectData}) {
  let setDate = newDate => {
    let curr = moment(instance.date).format('DD.MM.YYYY.');
    let saved = moment(project.end_date).format('DD.MM.YYYY.');
    if (curr !== saved) {
      setProjectData({target: {name: 'end_date', value: moment(instance.date).format('YYYY-MM-DD HH:mm:ss')}});
      return;
    }
  }

  useEffect(() => {
    var elem = document.querySelector('.datepicker');
    instance = M.Datepicker.init(elem, {format: 'dd.mm.yyyy.', firstDay: 1, onClose: setDate, minDate: new Date()});
    let date = moment(project.end_date).valueOf();
    instance.setDate(new Date(date));
  });

  return (
    <form className='project-form'>
      <div className="input-field">
        <input type="text" name="name" value={project.name} onChange={setProjectData}/>
        <label className="active">Name</label>
      </div>
      <div className="input-field">
        <input type="text" name="cell" value={project.cell} onChange={setProjectData}/>
        <label className="active">Cell</label>
      </div>
      <div className="input-field">
        <input type="text" name="end_date" value={project.end_date && moment(project.end_date).format('DD.MM.YYYY.')} onChange={setProjectData} className="datepicker"/>
        <label className={"active"}>End Date</label>
      </div>
      <div className="input-field">
        <input type="text" name="time" value={project.time} onChange={setProjectData}/>
        <label className="active">Time</label>
      </div>
      <div className="input-field">
        <input type="text" name="next_url" value={project.next_url} onChange={setProjectData}/>
        <label className="active">Next URL</label>
      </div>
    </form>
  )
}
