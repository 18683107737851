import React, {useEffect} from 'react';
import CButton from './CButton.jsx';
import Task from './Task.jsx';
import {Draggable} from '../helpers/Draggable.jsx';
let dnd;

export default function Tasks({tasks, onUpdateTasks}) {
  useEffect(() => {
    dnd = new Draggable(tasks);
  }, [tasks]);

  let onTaskUpdate = (task, idx) => {
    let tsks = JSON.parse(JSON.stringify(tasks));
    tsks[idx] = task;
    onUpdateTasks(tsks);
  }

  let createNewTask = () => {
    let newTask = {
      name: 'New Task',
      url: '',
      urlCheck: [''],
      desc: '',
      id: Date.now()
    }
    let tsks = JSON.parse(JSON.stringify(tasks));
    tsks.push(newTask);
    onUpdateTasks(tsks);
  }

  let deleteTask = idx => {
    let tsks = JSON.parse(JSON.stringify(tasks));
    tsks.splice(idx, 1);
    onUpdateTasks(tsks);
  }

  let onDragStart = (e, task) => {
    if (dnd)
    dnd.onDragStart(e, task);
  }

  let onDrop = (e, task) => {
    if (dnd)
    dnd.onDrop(e, task);
    let tasks = dnd.getElements();
    onUpdateTasks(tasks);
  }

  return (
    <div className="tasks">
      <div className="tasks-header">
        <h5> Tasks </h5>
        <CButton onSubmit={createNewTask}/>
      </div>

      {
        tasks.map((tsk, idx) => <Task task={tsk}
                                      key={tsk.id || idx}
                                      deleteTask={() => deleteTask(idx)}
                                      onTaskUpdate={task => onTaskUpdate(task, idx)}
                                      onDragStart={onDragStart}
                                      onDragOver={dnd.onDragOver}
                                      onDrop={onDrop}/>)
      }
    </div>
  )
}
