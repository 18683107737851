import React from 'react';
import {api} from '../api';
import {storage} from '../helpers/storage.js';

export default function Logout(props) {
  let logout = () => {
    let user = storage.getItem('user');
    if (!user) return;
    user = JSON.parse(user);
    api.logout({...user})
      .then(response => {})
      .catch(err => {})
      .finally(() => {
        storage.clear();
        props.history.push('/');
      })
  }

  return (
    <div id="logout" title="Log out">
      <i className="material-icons link pink-text" onClick={logout}>exit_to_app</i>
    </div>
  )
}
