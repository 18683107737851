import React from 'react';

export default function NextURLs({urls, updateURLs}) {
  let addUrl = e => {
    e.preventDefault();
    urls = [...urls];
    urls.push('');
    updateURLs(urls);
  }

  let removeUrl = (e, i) => {
    e.preventDefault();
    urls = [...urls];
    urls.splice(i, 1);
    updateURLs(urls);
  }

  let changeUrl = (e, i) => {
    e.preventDefault();
    urls = [...urls];
    let url = e.target.value;
    urls.splice(i, 1, url);
    updateURLs(urls);
  }

  return (
    <div>
        {
          urls.map((url, i) => (
            <div className="input-field url" key={i}>
              <input type="text" name="check_url" value={url} onChange={e => changeUrl(e, i)}/>
              <label className="active">Check URL</label>
              <button className="btn btn-floating btn-sm grey lighten-1" disabled={urls.length===1} onClick={e => removeUrl(e, i)}>
                <i className="material-icons">remove</i>
              </button>
              {
                i === urls.length - 1 ? (
                  <button className="btn btn-floating btn-sm grey lighten-1" onClick={addUrl}>
                    <i className="material-icons">add</i>
                  </button>
                ) : null
              }
             </div>
           ))
         }
    </div>

  )
}
