import React, {Component} from 'react';
import ProjectForm from './ProjectForm.jsx';
import {api} from '../api';
import Tasks from './Tasks.jsx';
import Logout from './Logout.jsx';
import {storage} from '../helpers/storage.js';
import M from 'materialize-css';

export default class Project extends Component{

  constructor(props) {
    super(props);
    this.projectStatus = this.getProjectStatus();

    this.state = {
      project: {
        name: 'New Project',
        cell: 1,
        next_url: '',
        end_date: new Date(),
        time: 1800,
        tasks: []
      },
      projectBeforeChanges: {}
    }
  }

  getProjectStatus = () => {
    let type = this.props.match.params.id;
    if (type === 'new-project')
      return 'new';
    if (Number(type) === Number(type)) {
      return 'existing';
    }
    if (type !== 'new-project'){
      return 'impossible';
    }
  }

  getProject = () => {
    let user = storage.getItem('user');
    if (!user) return;
    user = JSON.parse(user);

    api.fetchProjects({...user})
      .then(response => {
        let projects = response.data;
        let project = projects.find(project => project.id == this.props.match.params.id);
        this.index = projects.findIndex(project => project.id == this.props.match.params.id);
        if (!project) this.props.history.push('/projects');
        else {
          project.tasks = JSON.parse(project.tasks);
          project.tasks.forEach(task => {
            console.log('what is a task', task);
            if (typeof task.urlCheck === 'string') {
              task.urlCheck = [task.urlCheck];
            }
          })
          // if (typeof project.tasks === 'string')
          //   project.tasks = JSON.parse(project.tasks)
          this.setState({project, projectBeforeChanges: project})
        }
      })
      .catch(err => {
        M.toast({html: 'An error happened. Could not load the project.', classes: 'red'});
      })
  }

  setProjectData = (e) => {
    let project = JSON.parse(JSON.stringify(this.state.project));
    project[e.target.name] = e.target.value;
    this.setState({project});
  }

  onUpdateTasks = tasks => {
     let project = JSON.parse(JSON.stringify(this.state.project));
     project.tasks = tasks;
     this.setState({project});
  }

  onSaveProject = () => {
    if (JSON.stringify(this.state.project) === JSON.stringify(this.state.projectBeforeChanges)) {
      M.toast({html: 'Project successfully saved!', classes: 'teal lighten-2'});
      return;
    }
    this.saveProject();
  }

  saveProject = () => {
    let user = storage.getItem('user');
    if (!user) return;
    user = JSON.parse(user);

    let project = JSON.parse(JSON.stringify(this.state.project));

    api.saveProject({project:JSON.stringify(this.state.project), ...user})
      .then(response => {
        if (!project.id) {
          project.id = response.data;
        }
        this.setState({project, projectBeforeChanges: project});
        M.toast({html: 'Project successfully saved!', classes: 'teal lighten-2'});
      })
      .catch(err => {
        M.toast({html: 'An error happened. Could not save the project.', classes: 'red'});
      });
  }

  render () {
    return (
      <div className="project">
        <div className="table-header">
          <h4>Project</h4>
          <span>
            <i className="material-icons link pink-text" title="Save Project" onClick={this.onSaveProject}>save</i>
            <i className="material-icons link pink-text" title="Back to Projects" onClick={() => this.props.history.push('/projects')}>list</i>
          </span>
        </div>

        <ProjectForm project={this.state.project} setProjectData={this.setProjectData}/>
        <Tasks tasks={this.state.project.tasks} onUpdateTasks={this.onUpdateTasks}/>
        <Logout {...this.props}/>
      </div>
    )
  }

  componentDidMount() {
    if (this.projectStatus === 'existing') {
      this.getProject();
      return;
    }

    if (this.projectStatus === 'impossible') {
      this.props.history.push('/projects');
    }
  }
}
