export const g = {
  objectEquals: function (x, y) {
    if (x === null || x === undefined || y === null || y === undefined) {
      return x === y;
    }
    if (x.constructor !== y.constructor) {
      return false;
    }
    if (x instanceof Function) {
      return x === y;
    }
    if (x instanceof RegExp) {
      return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
      return true;
    }
    if (Array.isArray(x) && x.length !== y.length) {
      return false;
    }
    if (x instanceof Date) {
      return false;
    }
    if (!(x instanceof Object)) {
      return false;
    }
    if (!(y instanceof Object)) {
      return false;
    }
    var p = Object.keys(x);
    return Object.keys(y).every(function(i) {
        return p.indexOf(i) !== -1;
      }) &&
      p.every(function(i) {
        return g.objectEquals(x[i], y[i]);
      });
  },

  findObjByProp (arr, objProp, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][objProp] === value)
        return arr[i];
    }
    return null;
  },

  findIndexByProp (arr, objProp, value) {
    if (!arr) return;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][objProp] === value)
        return i;
    }
    return -1;
  },

  deepCopy (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
}
