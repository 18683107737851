import React from 'react';

export default function CButton({onSubmit}) {
  return (
    <button className="btn pink c-btn" onClick={onSubmit}>
      <i className="material-icons">add</i>
      <span>Create</span>
    </button>
  )
}
