import {instance as axios} from './config.js';

export const api = {
  login(data) {
    return axios.post('/login', data);
  },

  logout(data) {
    return axios.post('/logout', data);
  },

  exportProject(data) {
    return axios.post('/export', data);
  },

  fetchProjects(params) {
    return axios.get('/projects', {params});
  },

  saveProject(project) {
    return axios.post('/project', project);
  },

  deleteProject(project) {
    return axios.delete('/project', {params: project});
  }
}
