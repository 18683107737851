import React, {Component} from 'react';
import {api} from '../api';
import {storage} from '../helpers/storage.js';
import ProjectRow from './ProjectRow.jsx';
import CButton from './CButton.jsx';
import moment from 'moment';
import Logout from './Logout.jsx';
import M from 'materialize-css';
import Modal from './Modal.jsx';
import DeleteProject from './DeleteProject.jsx';
import LinkPreview from './Link.jsx';

export default class Projects extends Component {
  state = {
    projects: [],
    filteredProjects: [],
    isModalVisible: false,
    modalData: null,
    link: {
      link: '',
      style: {display: 'none'}
    }
  }

  onDeleteProject = (id, e) => {
    e.stopPropagation();
    this.setState({isModalVisible: true, modalData: id})
  }

  deleteProject = id => {
    this.hideModal();

    document.getElementById('searchFor').value = '';
    let projects = JSON.parse(JSON.stringify(this.state.projects));
    let projectIdx = projects.findIndex(project => project.id === id);
    let user = storage.getItem('user');
    if (!user) return;
    user = JSON.parse(user);

    api.deleteProject({id, ...user})
     .then(response => {
       projects.splice(projectIdx, 1);
       this.setState({projects, filteredProjects: projects});
       M.toast({html: 'Project successfully deleted', classes: 'teal lighten-2'});
     })
    .catch(err => {
      M.toast({html: 'An error happened. Could not delete the project.', classes: 'red'});
    });
  }

  duplicateProject = id => {
    let filter = document.getElementById('searchFor').value.toLowerCase().trim();

    let projects = JSON.parse(JSON.stringify(this.state.projects));
    let project = projects.find(project => project.id === id);
    project = JSON.parse(JSON.stringify(project));
    project.name += ' - copy';
    delete project.id;
    if (typeof project.tasks !== 'object')
      project.tasks = JSON.parse(project.tasks);

    let user = storage.getItem('user');
    if (!user) return;
    user = JSON.parse(user);
    api.saveProject({project: JSON.stringify(project), ...user})
     .then(response => {
       project.id = response.data;
       projects.unshift(project);
       let filteredProjects = projects.filter(project => project.name && project.name.toLowerCase().indexOf(filter) >= 0);
       this.setState({projects, filteredProjects});
     })
     .catch(err => {
       M.toast({html: 'An error happened. Could not duplicate the project.', classes: 'red'});
     })
  }

  hideModal = () => {
    this.setState({isModalVisible: false, modalData: null});
  }

  filterProjects = e => {
    let searchFor = e.target.value;
    searchFor = searchFor.toLowerCase().trim();
    window.localStorage.setItem("searchFor", searchFor);
    let filteredProjects = JSON.parse(JSON.stringify(this.state.projects));
    filteredProjects = filteredProjects.filter(project => project.name && project.name.toLowerCase().indexOf(searchFor) >= 0);
    this.setState({filteredProjects});
  }

  exportCSV = project => {
    api.exportProject(project)
      .then(response => {
        var link = document.createElement("a");
        link.setAttribute("href", 'test');
        link.setAttribute("download", `${project.name}_${moment().format('DD.MM.YYYY')}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  previewLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    let link = {
      link: url,
      style: {
        top: (e.clientY + 10) + 'px',
        left: (e.clientX - 400) + 'px',
        display: 'flex'
      }
    }
    this.setState({link});
  }

  hideLink = e => {
    let link = {
      link: '',
      style: {
        top: '0px',
        left: '0px',
        display: 'none'
      }
    }
    return link;
  }

  hideAll = () => {
    let link = this.hideLink();
    this.setState({isModalVisible: false, modalData: null, link})
  }

  sortBy = (key, order,e) => {
    let filteredProjects = JSON.parse(JSON.stringify(this.state.projects));

    if (order === 'asc') {
      filteredProjects.sort((p1, p2) => {
        if (key === 'end_date') {
          return moment(p1[key]) - moment(p2[key]);
        }
        return p1[key] - p2[key];
      });
    } else {
      filteredProjects.sort((p1, p2) => {
        if (key === 'end_date') {
          return moment(p2[key]) - moment(p1[key]);
        }
        return p2[key] - p1[key];
      });

    }
    this.setSortIconStyle(e);
    this.setState({filteredProjects});
  }

  setSortIconStyle = e => {
    let sortIcons = document.querySelectorAll('.sort-icon');
    for (let i = 0; i < sortIcons.length; i++) {
      sortIcons[i].style.color = '#dfdfdf';
    }
    e.target.style.color = '#333';
  }

  render() {
    return (
        <div id="projects" onClick={this.hideAll}>
          <Modal isVisible={this.state.isModalVisible} >
            <DeleteProject deleteProject={() => this.deleteProject(this.state.modalData)} hideModal={this.hideModal}/>
          </Modal>

          <div className="table-header">
            <h4>Projects</h4>
            <CButton onSubmit={() => this.props.history.push('/projects/new-project')}/>
          </div>

          <div className="projects-table">
            <table>
              <thead>
                <tr>
                  <th className="sortable">
                    <span>id</span>
                    <span className="sort-ctrls">
                      <i className="sort-icon material-icons" onClick={(e) => this.sortBy('id', 'asc',e)}>arrow_drop_up</i>
                      <i className="sort-icon material-icons desc default" onClick={(e) => this.sortBy('id', 'desc',e)}>arrow_drop_down</i>
                    </span>
                  </th>
                  <th className="filterable">
                    <i className="material-icons">search</i>
                    <input className="invisible-borders" id="searchFor" placeholder='Name (Cell)' onFocus={e => e.target.placeholder=''} onBlur={e => e.target.placeholder='Name (Cell)'} onKeyUp={this.filterProjects}/>
                  </th>
                  <th className="sortable">
                    <span>End date</span>
                    <span className="sort-ctrls">
                      <i className="sort-icon material-icons" onClick={(e) => this.sortBy('end_date', 'asc',e)}>arrow_drop_up</i>
                      <i className="sort-icon material-icons desc" onClick={(e) => this.sortBy('end_date', 'desc',e)}>arrow_drop_down</i>
                    </span>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {
                  this.state.filteredProjects.map((project, idx) => (
                    <ProjectRow key={project.id}
                                project={project}
                                exportCSV={() => this.exportCSV(project)}
                                previewLink={this.previewLink}
                                duplicateProject={() => this.duplicateProject(project.id)}
                                deleteProject={e => this.onDeleteProject(project.id, e)}/>
                  ))
                }
              </tbody>
            </table>
          </div>
          <LinkPreview {...this.state.link}/>
          <Logout {...this.props}/>
        </div>
    )
  }

  componentDidMount () {
    let user = storage.getItem('user');
    if (!user) return;
    user = JSON.parse(user);

    api.fetchProjects({...user})
      .then(response => {
        let projects = response.data.sort((a, b) => b.id - a.id);
        let searchFor = window.localStorage.getItem('searchFor');
        let filteredProjects = projects;
        if (searchFor) {
          filteredProjects = filteredProjects.filter(project => project.name && project.name.toLowerCase().indexOf(searchFor) >= 0);
          document.getElementById('searchFor').value = searchFor;
        }
        this.setState({
          projects,
          filteredProjects
        })
      })
      .catch(err => {
        M.toast({html: 'An error happened. Could not load the projects.', classes: 'red'});
      })
  }
}
