import React, {useEffect} from 'react';
import {api} from './api'
import 'materialize-css/dist/css/materialize.min.css';
import './assets/index.scss';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Login from './components/Login.jsx';
import Projects from './components/Projects.jsx';
import Project from './components/Project.jsx';

function App() {
  useEffect(() => {
    window.onbeforeunload = () => window.localStorage.removeItem('searchFor');
  });
  
  return (
    <Router>
        <div className="App">
         <Route path="/" exact component={Login} />
         <PrivateRoute path="/projects" exact component={Projects} />
         <PrivateRoute path="/projects/:id" component={Project} />
        </div>
    </Router>
  );
}


function PrivateRoute({component: Component, ...rest}) {
  return (
    <Route {...rest} render={props => window.localStorage.getItem('user') ? <Component {...props} /> : <Redirect to="/" />} />
  )
}
export default App;
